import { closeModal } from "app/slices/modalSlice";

import Modal from "../modal";
import { useTranslation } from "react-i18next";
import { FormProvider, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { RouterSupportedDOCountry } from "interfaces/enums";
import { useGetUserQuery } from "api/institutional/user";
import { Account } from "./AccountForm";
import { useGetRouterSubAccountsQuery } from "api/institutional/router_api";

import { IRouterCreateBankAcc } from "interfaces/router";

export const AddBankAccountModalRouter = ({
  data,
}: {
  data: { country: RouterSupportedDOCountry };
}) => {
  const { t } = useTranslation();

  const handleClose = () => {
    dispatch(closeModal());
  };

  const dispatch = useDispatch();

  const { data: user } = useGetUserQuery({});

  const { data: subAccounts = [] } = useGetRouterSubAccountsQuery(undefined, {
    skip: !user?.isRouterBroker,
  });

  const methods = useForm<IRouterCreateBankAcc>({
    mode: "all",
    resetOptions: { keepIsValid: false },
    shouldUnregister: true,
    defaultValues: {
      account_name: "",
      country: data.country,
    },
  });

  if (!user) return null;

  return (
    <Modal>
      <Modal.Header handleClose={handleClose}>
        <span className="md:text-7 text-6 font-interSemiBold text-black">
          {t("Add New Destination Bank Account")}
        </span>
      </Modal.Header>
      <Modal.Body>
        <FormProvider {...methods}>
          <Account
            handleClose={handleClose}
            subAccounts={subAccounts}
            user={user}
          />
        </FormProvider>
      </Modal.Body>
    </Modal>
  );
};

export default AddBankAccountModalRouter;
