import { RouterSupportedDOCountry } from "../interfaces/enums";

export const getBankOptions = (country: RouterSupportedDOCountry) => {
  switch (country) {
    case "MX":
      return [
        "Banco Nacional de México",
        "Banco Nacional de Comercio Exterior",
        "Banco Nacional de Obras y Servicios Públicos",
        "BBVA Bancomer",
        "Banco Santander",
        "Banco Nacional del Ejército, Fuerza Aérea y Armada",
        "HSBC México",
        "Banco del Bajío",
        "Banco Inbursa",
        "Banco Mifel",
        "Scotiabank",
        "Banco Regional de Monterrey",
        "Banco Invex",
        "Banco del Ahorro Nacional y Servicios Financieros",
        "Banco Afirme",
        "Banco Mercantil",
        "Bank of America",
        "MUFG Bank",
        "J.P. Morgan",
        "Banco Monex",
        "Banco Ve Por Más",
        "Credit Suisse",
        "Banco Azteca",
        "Banco Autofin",
        "Barclays",
        "Banco Compartamos",
        "Banco Multiva",
        "Banco Actinver",
        "Nacional Financiera",
        "Banco Intercam",
        "BanCoppel",
        "ABC Capital",
        "Consubanco",
        "Volkswagen Bank",
        "CIBanco",
        "Banco BASE",
        "Bankaool",
        "Banco Pagatodo",
        "Banco Dondé",
        "Banco Inmobiliario (BIM)",
        "Bancrea",
        "Banco Covalto",
        "ICBC México",
        "Banco Sabadell",
        "Banco Shinhan",
        "Mizuho Bank",
        "Bank of China",
        "Banco S3 CACEIS",
        "Banco del Bienestar",
        "Hipotecaria Federal",
        "STP",
        "Arcus",
      ];
    case "US":
      return [
        "JPMorgan Chase",
        "Bank of America",
        "Citigroup",
        "Wells Fargo",
        "IBC",
        "Goldman Sachs",
        "Morgan Stanley",
        "U.S. Bancorp",
        "PNC Financial Services",
        "Truist Financial",
        "TD Bank, N.A.",
        "Capital One",
        "Charles Schwab Corporation",
        "The Bank of New York Mellon",
        "State Street Corporation",
        "BMO USA",
        "American Express",
        "HSBC Bank USA",
        "Citizens Financial Group",
        "First Citizens BancShares",
        "USAA",
        "M&T Bank",
        "Fifth Third Bank",
        "Barclays",
        "UBS",
        "Huntington Bancshares",
        "Ally Financial",
        "KeyCorp",
        "Ameriprise",
        "RBC Bank",
        "Santander Bank",
        "Northern Trust",
        "Regions Financial Corporation",
        "Discover Financial",
        "Synchrony Financial",
        "Deutsche Bank",
        "New York Community Bank",
        "First Horizon National Corporation",
        "Raymond James Financial",
        "Comerica",
        "Western Alliance Bancorporation",
        "Webster Bank",
        "Mizuho Financial Group",
        "Popular, Inc.",
        "East West Bank",
        "CIBC Bank USA",
        "BNP Paribas",
        "John Deere",
        "Valley Bank",
        "Synovus",
        "Wintrust Financial",
        "Columbia Bank",
        "BOK Financial Corporation",
        "Cullen/Frost Bankers, Inc.",
        "Old National Bank",
        "Pinnacle Financial Partners",
        "FNB Corporation",
        "UMB Financial Corporation",
        "South State Bank",
        "Associated Banc-Corp",
        "Prosperity Bancshares",
        "Stifel",
        "EverBank",
        "Midland",
        "Banc of California",
        "Hancock Whitney",
        "BankUnited",
        "Sumitomo Mitsui Banking Corporation",
        "SoFi",
        "First National of Nebraska",
        "Commerce Bancshares",
        "First Interstate BancSystem",
        "WaFd Bank",
        "United Bank (West Virginia)",
        "Texas Capital Bank",
        "Glacier Bancorp",
        "FirstBank Holding Co",
        "Fulton Financial Corporation",
        "Simmons Bank",
        "United Community Bank",
        "Arvest Bank",
        "BCI Financial Group",
        "Ameris Bancorp",
        "First Hawaiian Bank",
        "Bank of Hawaii",
        "Cathay Bank",
        "Credit Suisse[a]",
        "Home BancShares",
        "Beal Bank",
        "Axos Financial",
        "Atlantic Union Bank",
        "Customers Bancorp",
        "Eastern Bank",
        "WSFS Bank",
        "Pinnacle Bank",
        "Independent Bank",
        "HTLF Bank / Heartland Financial",
        "Central Bancompany, Inc.",
        "First BanCorp",
        "Independent Bank Group, Inc.",
        "Pacific Premier Bancorp",
      ];
    default:
      return [];
  }
};
