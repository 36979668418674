export const BANKS = {
  138: "40138",
  133: "40133",
  "062": "40062",
  661: "90661",
  638: "90638",
  706: "90706",
  659: "90659",
  128: "40128",
  127: "40127",
  166: "37166",
  "030": "40030",
  "002": "40002",
  154: "40154",
  "006": "37006",
  137: "40137",
  160: "40160",
  152: "40152",
  "019": "37019",
  147: "40147",
  106: "40106",
  159: "40159",
  "009": "37009",
  "072": "40072",
  "058": "40058",
  "060": "40060",
  "001": "2001",
  129: "40129",
  145: "40145",
  "012": "40012",
  112: "40112",
  677: "90677",
  683: "90683",
  630: "90630",
  143: "40143",
  631: "90631",
  901: "90901",
  903: "90903",
  130: "40130",
  140: "40140",
  652: "90652",
  688: "90688",
  126: "40126",
  680: "90680",
  723: "90723",
  151: "40151",
  616: "90616",
  634: "90634",
  689: "90689",
  685: "90685",
  601: "90601",
  168: "37168",
  "021": "40021",
  155: "40155",
  "036": "40036",
  902: "90902",
  150: "40150",
  136: "40136",
  686: "90686",
  "059": "40059",
  110: "40110",
  653: "90653",
  670: "90670",
  602: "90602",
  "042": "40042",
  158: "40158",
  600: "90600",
  108: "40108",
  132: "40132",
  613: "90613",
  135: "37135",
  710: "90710",
  684: "90684",
  148: "40148",
  620: "90620",
  156: "40156",
  "014": "40014",
  "044": "40044",
  157: "40157",
  646: "90646",
  648: "90648",
  656: "90656",
  617: "90617",
  605: "90605",
  608: "90608",
  703: "90703",
  113: "40113",
  141: "40141",
};
// Descargado de https://www.banxico.org.mx/cep-scl/listaInstituciones.do 2022-10-18
export const BANK_NAMES = {
  40138: "ABC CAPITAL",
  40133: "ACTINVER",
  40062: "AFIRME",
  90706: "ARCUS",
  90659: "ASP INTEGRA OPC",
  40128: "AUTOFIN",
  40127: "AZTECA",
  37166: "BaBien",
  40030: "BAJIO",
  40002: "BANAMEX",
  40154: "BANCO COVALTO",
  37006: "BANCOMEXT",
  40137: "BANCOPPEL",
  40160: "BANCO S3",
  40152: "BANCREA",
  37019: "BANJERCITO",
  40147: "BANKAOOL",
  40106: "BANK OF AMERICA",
  40159: "BANK OF CHINA",
  37009: "BANOBRAS",
  40072: "BANORTE",
  40058: "BANREGIO",
  40060: "BANSI",
  2001: "BANXICO",
  40129: "BARCLAYS",
  40145: "BBASE",
  40012: "BBVA MEXICO",
  40112: "BMONEX",
  90677: "CAJA POP MEXICA",
  90683: "CAJA TELEFONIST",
  90630: "CB INTERCAM",
  40124: "CBM BANCO",
  40143: "CIBANCO",
  90631: "CI BOLSA",
  90901: "CLS",
  90903: "CoDi Valida",
  40130: "COMPARTAMOS",
  40140: "CONSUBANCO",
  90652: "CREDICAPITAL",
  90688: "CREDICLUB",
  90680: "CRISTOBAL COLON",
  90723: "Cuenca",
  40151: "DONDE",
  90616: "FINAMEX",
  90634: "FINCOMUN",
  90689: "FOMPED",
  90699: "FONDEADORA",
  90685: "FONDO (FIRA)",
  90601: "GBM",
  37168: "HIPOTECARIA FED",
  40021: "HSBC",
  40155: "ICBC",
  40036: "INBURSA",
  90902: "INDEVAL",
  40150: "INMOBILIARIO",
  40136: "INTERCAM BANCO",
  40059: "INVEX",
  40110: "JP MORGAN",
  90661: "KLAR",
  90653: "KUSPIT",
  90670: "LIBERTAD",
  90602: "MASARI",
  90722: "Mercado Pago W",
  40042: "MIFEL",
  40158: "MIZUHO BANK",
  90600: "MONEXCB",
  40108: "MUFG",
  40132: "MULTIVA BANCO",
  37135: "NAFIN",
  90638: "NU MEXICO",
  90710: "NVIO",
  40148: "PAGATODO",
  90620: "PROFUTURO",
  40156: "SABADELL",
  40014: "SANTANDER",
  40044: "SCOTIABANK",
  40157: "SHINHAN",
  90728: "SPIN BY OXXO",
  90646: "STP",
  90703: "TESORED",
  90684: "TRANSFER",
  90656: "UNAGRA",
  90617: "VALMEX",
  90605: "VALUE",
  90608: "VECTOR",
  40113: "VE POR MAS",
  40141: "VOLKSWAGEN",
};
