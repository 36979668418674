import { useLocation, useNavigate } from "react-router-dom";
import { useGetUserQuery } from "api/institutional/user";
import { Locations, Roles, Titles } from "interfaces/enums";

import { ISidebarMenuOptions } from "interfaces";
import { useTranslation } from "react-i18next";

export const MobileButton = ({
  icon,
  selected,
  to,
  handleClose,
}: {
  icon: any;
  selected: boolean;
  to: Locations;
  handleClose: () => void;
}) => {
  const navigate = useNavigate();

  const { t } = useTranslation();

  const handleRedirect = () => {
    handleClose();
    navigate(to);
  };

  const element = () => (
    <div className="flex gap-3 align-middle justify-center">
      {icon({
        color: selected ? "white" : "#505050",
        style: { fill: selected ? "white" : "#505050" },
      })}
      <span
        className={`text-4 ${selected ? "text-white" : "text-gray-dark500"}`}
        data-testid={`main-title-span-header`}
      >
        {t(Titles[to])}
      </span>
    </div>
  );

  if (selected) {
    return (
      <div className="rounded-md justify-start overflow-hidden text-white bg-black p-2 py-3 w-full">
        <div className="flex">{element()}</div>
      </div>
    );
  }
  return (
    <button
      onClick={handleRedirect}
      className="rounded-md cursor-pointer hover:bg-gray-light100 p-2 w-full py-3"
    >
      <div className="flex">{element()}</div>
    </button>
  );
};

export const MobileMenuItem = ({
  option,
  isBottomBar,
  handleClose,
}: {
  isBottomBar?: boolean;
  handleClose: any;
  option: ISidebarMenuOptions;
}) => {
  const pathname = useLocation().pathname as Locations;

  const { data: user } = useGetUserQuery({});

  /** Hide admin menus */
  if (option.role && option.role === Roles.admin && !user?.is_admin) {
    return null;
  }

  if (option.role && option.role === Roles.admin && user?.is_admin) {
    return (
      <MobileButton
        selected={pathname === option.route}
        icon={option.icon}
        to={option.route}
        handleClose={handleClose}
      />
    );
  }

  /** Hide mobile menus */
  if (option.hideOnMobile && isBottomBar) {
    return null;
  }

  /** Hide based on flags menus */
  if (!user?.flags.some((flag) => option.showTo.includes(flag))) {
    return null;
  }

  /** Hide nav bar menus */
  if (option.hideOnNavBar) {
    return null;
  }

  return (
    <MobileButton
      selected={pathname === option.route}
      icon={option.icon}
      to={option.route}
      handleClose={handleClose}
    />
  );
};
