/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";
import {
  FileStructure,
  ICcyConversionOrdersQuery,
  IOnBoarding,
  IOnboardingDraft,
} from "interfaces";
import { IDTypes, KYBStatus } from "interfaces/enums";

type SliceState = {
  ccyConversionOrdersQuery: ICcyConversionOrdersQuery;
  selectedOrderId: string;
  selectedDestinationId: string;
  selectedDestinationIndex: number;
  isClaimTransferActionEnabled: boolean;

  onboarding: IOnBoarding;
  step: number;
  uploading: Array<string>;
  previewFile: string;
};

// Setting the default state of live_only allows the system to save in cache the
// initial request to avoid having to make an additional request
const initialState: SliceState = {
  ccyConversionOrdersQuery: {
    live_only: false,
    sub_account_id: "",
    user_id: "",
  },
  selectedOrderId: "",
  selectedDestinationId: "",
  selectedDestinationIndex: -1,
  isClaimTransferActionEnabled: false,
  onboarding: {
    id: "",
    status: KYBStatus.IN_PROGRESS,
    company_name: "",
    email: "",
    regulated: null,
    legal_rep_name: "",
    company_category: "",
    corporate_shareholders: [],
    files: [],
    id_type: IDTypes.INE,
  },
  step: 0,
  uploading: [],
  previewFile: "",
};

export const globalSlice = createSlice({
  name: "global",
  initialState,
  reducers: {
    reset: () => initialState,
    forwardStep: (state) => {
      state.step = state.step + 1;
    },
    setUploading: (state, action: { payload: { category: string } }) => {
      state.uploading.push(action.payload.category);
    },
    removeUploading: (state, action: { payload: { category: string } }) => {
      state.uploading = state.uploading.filter(
        (s) => s !== action.payload.category,
      );
    },
    backStep: (state) => {
      state.step = state.step - 1;
    },
    setPreviewFile: (state, action: { payload: { previewFile: string } }) => {
      state.previewFile = action.payload.previewFile;
    },
    setIdType: (
      state,
      action: {
        payload: { id_type: IDTypes };
      },
    ) => {
      state.onboarding.id_type = action.payload.id_type;
    },
    setKYC: (
      state,
      action: {
        payload: IOnboardingDraft;
      },
    ) => {
      Object.assign(state.onboarding, action.payload);
    },
    removeFile: (
      state,
      action: {
        payload: {
          filename: string;
          category: string;
        };
      },
    ) => {
      const { category, filename } = action.payload;

      try {
        //@ts-ignore
        state.onboarding[category] = state.onboarding[category].filter(
          //@ts-ignore
          (_, index) =>
            index !==
            //@ts-ignore
            state.onboarding[category].findIndex(
              (f: any) => f.filename === filename,
            ),
        );
      } catch (e) {
        console.log(e);
      }
    },
    addDocument: (
      state,
      action: {
        payload: {
          filename: string;
          category: string;
        };
      },
    ) => {
      const {
        payload: { filename, category },
      } = action;

      const newFile: FileStructure = {
        filename: filename,
        category: category,
      };

      //@ts-ignore
      if (state.onboarding[category]?.length) {
        //@ts-ignore
        state.onboarding[category].push(newFile);
      } else {
        //@ts-ignore
        state.onboarding[category] = [newFile];
      }
    },
    setSelectedCCY: (
      state,
      action: {
        payload: {
          selectedOrderId: string;
          selectedDestinationId?: string;
          selectedDestinationIndex?: number;
        };
      },
    ) => {
      Object.assign(state, action.payload);
    },
    clearSelectedCCY: (state) => {
      state.selectedDestinationId = "";
      state.selectedOrderId = "";
      state.selectedDestinationIndex = -1;
      state.isClaimTransferActionEnabled = false;
    },
    clearCcyConversionOrdersQuery: (state) => {
      state.ccyConversionOrdersQuery = {
        ...initialState.ccyConversionOrdersQuery,
      };
    },
    setCcyConversionOrdersQuery: (state, action) => {
      // Only modifies the parameters of the state included in the action payload,
      // and maintains the rest
      Object.assign(state.ccyConversionOrdersQuery, action.payload);
    },
    setClaimTransferActionEnabled: (state) => {
      state.isClaimTransferActionEnabled = true;
    },
    setShowActiveOnlyCcyConversionOrdersQuery: (state, action) => {
      state.ccyConversionOrdersQuery.live_only = action.payload;
    },
  },
});

export const {
  reset,
  setKYC,
  setSelectedCCY,
  addDocument,
  clearCcyConversionOrdersQuery,
  removeFile,
  clearSelectedCCY,
  setCcyConversionOrdersQuery,
  setShowActiveOnlyCcyConversionOrdersQuery,
  setClaimTransferActionEnabled,
  setIdType,
  forwardStep,
  backStep,
  setPreviewFile,
  setUploading,
  removeUploading,
} = globalSlice.actions;

export type GlobalState = ReturnType<typeof globalSlice.reducer>;
