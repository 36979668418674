import { es, enUS } from "date-fns/locale";
import {
  BankAccountNumberByTransactionType,
  DestinationBankAccountClassName,
  RouterSupportedFiat,
  SyntaticRouterSupportedFiat,
  TransactionTypeOptions,
} from "../interfaces/enums";
import {
  IDestinationBankAccountAll,
  IDestinationBankAccountMexicanRouter,
  IDestinationBankAccountUSRouter,
  IError,
  ISelectOption,
} from "../interfaces";
import { useFormContext } from "react-hook-form";
import { IRouterCreateBankAcc } from "interfaces/router";

export const findOption = (value: any, options: Array<ISelectOption>) =>
  options?.find((option) =>
    option.value === value ? { label: option, value: option } : null,
  );

export const valueLabelMapper = (options: Array<string>) =>
  options?.map((option: string) => ({ label: option, value: option }));

const pad = (num: number) => (num < 10 ? `0${num}` : `${num}`);

export const toIsoString = (date: Date) => {
  const tzo = -date.getTimezoneOffset();
  const dif = tzo >= 0 ? "+" : "-";

  return `${date.getFullYear()}-${pad(date.getMonth() + 1)}-${pad(
    date.getDate(),
  )} ${pad(date.getHours())}:${pad(date.getMinutes())}:${pad(
    date.getSeconds(),
  )}${dif}${pad(Math.floor(Math.abs(tzo) / 60))}:${pad(Math.abs(tzo) % 60)}`;
};

export const localeSelector = (lang?: string) => {
  if (lang === "es") return es;
  else return enUS;
};

export const generateAccountAlias = (account: IDestinationBankAccountAll) => {
  if (account.class_name === DestinationBankAccountClassName.SPID)
    return {
      label: `${account.recipient_name} - ${account.bank_name} - ${
        account.class_name &&
        BankAccountNumberByTransactionType[account.class_name]
      }: ${account.clabe_dest} - ${
        account.class_name && TransactionTypeOptions[account.class_name]
      }`,
      value: account.id,
    };

  if (account.class_name === DestinationBankAccountClassName.UsDomesticWire) {
    return {
      label: `${account.recipient_name} - ${account.bank_name} - ${
        account.class_name &&
        BankAccountNumberByTransactionType[account.class_name]
      }: ${account.acct_number_dest} - ${
        account.class_name && TransactionTypeOptions[account.class_name]
      }`,
      value: account.id,
    };
  }

  if (account.class_name === DestinationBankAccountClassName.CryptoWallet) {
    return {
      label: `${account.alias} - ${account.network} - ${account.wallet_address}`,
      value: account.id,
    };
  }
};

export const generateAccountAliasRouterMx = (
  account: IDestinationBankAccountMexicanRouter,
) => {
  return {
    label: `${account.account_name} - ${account.bank_name} - ${account.clabe} - ${account.class_name}`,
    value: account.id,
  };
};

export const generateOnlyAlias = (account: IRouterCreateBankAcc) => {
  return [
    account.account_name,
    account.bank_name,
    account.clabe,
    account.account_number_type,
    account.transfer_method,
    account.class_name,
  ]
    .filter((f) => f)
    .join(" - ");
};

export const generateAccountAliasRouter = (account: IRouterCreateBankAcc) => {
  return {
    label: [
      account.account_name,
      account.bank_name,
      account.clabe,
      account.account_number_type,
      account.transfer_method,
      account.class_name,
    ]
      .filter((f) => f)
      .join(" - "),
    value: account.id,
  };
};

export const generateAccountAliasRouterUS = (
  account: IDestinationBankAccountUSRouter,
) => {
  return {
    label: `${account.account_name} - ${account.bank_name} - ${account.account_number} - ${account.account_type} - ${account.transfer_method}`,
    value: account.id,
  };
};

export const emptyValue = "----";

export const transformErrorResponse = (error: any | IError) => {
  // Missing fields type error
  if (Array.isArray(error?.data?.detail)) {
    const { loc, msg /*type*/ } = error.data.detail[0];
    return `${msg} ${loc?.[1] || ""}`;
  }
  return (
    error?.data?.detail! ||
    error?.data ||
    error ||
    "An error occurred. Please notifiy Ridian."
  );
};

export const removeUndefinedValues = <T extends Record<string, any>>(
  obj: T,
): T => {
  return Object.fromEntries(
    Object.entries(obj).filter(
      ([_, value]) => value !== undefined && value !== "",
    ),
  ) as T;
};

export const useTrimmer = () => {
  const { setValue, getValues } = useFormContext();
  const onBlur = (field: any) => {
    setValue(field.target.name, getValues(field.target.name)?.trim());
  };

  return { onBlur };
};

export const transformToValidFiat = (fiat: SyntaticRouterSupportedFiat) => {
  if (fiat === SyntaticRouterSupportedFiat["MexDolar"]) {
    return RouterSupportedFiat.USD;
  }
  return fiat;
};
